@charset "UTF-8";
h1, h2 {
  color: #c7181d;
}

.highlight {
  color: #c7181d;
  font-weight: bold;
}

.blue {
  color: #0066ff;
}

.mybtn {
  background-color: #f74905;
  padding: 10px 20px;
  border-radius: 5px;
  color: #FFF;
  display: inline-block;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.75);
}
.mybtn:hover {
  color: #FFF;
}
.mybtn:focus, .mybtn:active {
  background-color: #f74905;
}

.btn-wrap {
  margin: 20px 0;
}

#content .container {
  max-width: 960px;
}
#content .container img {
  max-width: 100%;
}

section {
  font-family: "Apple LiGothic Medium", "微軟正黑體", "Microsoft JhengHei", Arial;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
  border-bottom: 10px solid #cccccc;
}
section:last-child {
  border-bottom: 0;
}
section ul {
  padding: 0;
  margin: 0;
}
section ul li {
  list-style: inherit;
  margin-left: 20px;
}
section.ktoys {
  background-color: #ffff4c;
}
section.ktoys .content {
  background-image: url(../images/home/ktoys-bg.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  margin: 0;
}
section.ktoys .left {
  padding: 0 30px;
}
section.ktoys .right {
  padding: 0 30px;
}
section.ktoys .right a {
  margin-top: 100px;
  display: inline-block;
}
section.introduction {
  padding: 20px;
}
section.contact {
  padding: 30px;
}
section.contact .approach {
  display: block;
}
section.contact .approach > * {
  display: inline-block;
}
section.contact .approach img {
  vertical-align: top;
}
section.contact .approach div span {
  display: block;
}
section.contact .approach div a {
  font-size: 13px;
}

.owl-theme .owl-controls {
  position: absolute;
  top: 40%;
  width: 100%;
}
.owl-theme .owl-controls .owl-prev {
  left: 0;
  position: absolute;
  width: 36px;
}
.owl-theme .owl-controls .owl-next {
  right: 0;
  position: absolute;
  width: 36px;
}

@media (max-width: 480px) {
  .top-other .top-search {
    display: none;
  }

  section {
    border-bottom-width: 5px;
  }
  section.contact {
    padding: 10px;
  }
  section.contact .email {
    font-size: 15px;
  }
}